/**
 * Reset captcha after use.
 */
const resetCaptchaWidgets = () => {
    $('.g-recaptcha').each((gr) => {
        // eslint-disable-next-line no-undef
        grecaptcha.reset(gr);
    });
};

/**
 * Used for rendering captcha on load
 */
const loadCaptchas = () => {
    $('.g-recaptcha').each(function () {
        try {
            const object = $(this);
            // eslint-disable-next-line no-undef
            const widgetId = grecaptcha.render(object.attr('id'), {
                sitekey: object.data('sitekey'),
            }, true);
            $(this).attr('data-widget-number', widgetId);
            if (!!window.gRecaptchaHandler && (window.gRecaptchaHandler.instanceType !== '0')) {
                if (window[$(this).data('callback')] === undefined) {
                    window[$(this).data('callback')] = function () {};
                }
                if (window[$(this).data('error-callback')] === undefined) {
                    window[$(this).data('error-callback')] = function () {};
                }
                if (window[$(this).data('expired-callback')] === undefined) {
                    window[$(this).data('expired-callback')] = function () {};
                }
            }
        } catch (e) {
            // Log only for non production instances
            if (!!window.gRecaptchaHandler && (window.gRecaptchaHandler.instanceType === '0' || window.gRecaptchaHandler.instanceType === '1')) {
                // eslint-disable-next-line no-console
                console.info(e);
            }
        }
    });
};
export default class GRecaptchaHandler {
    init() {
        this.usedRecaptchaReset();
        window.loadRecaptchaCustom = loadCaptchas;
    }

    usedRecaptchaReset() {
        // Reset captcha widget after use
        $(document).ajaxSuccess((event, jqXHR, ajaxOptions, data) => {
            // eslint-disable-next-line no-undef
            if (data && data.usedCaptcha && grecaptcha) {
                resetCaptchaWidgets();
            }
        });
        // Reset captcha widget in case of server error
        // eslint-disable-next-line no-unused-vars
        $(document).ajaxError((event, jqXHR, ajaxOptions, thrownError) => {
            resetCaptchaWidgets();
        });
    }
}
